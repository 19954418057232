.rs-codes{
    .codes-button{
        a{
            position: relative;
            display: inline-block;
            transition: $transition;
            padding: 18px 0px 0px 0px;
            font-size: 16px;
            font-weight: 500;
            color: #101010;
            &:after{
                content: "\f113";
                font-family: Flaticon;
                font-size: 10px;
                position: relative;
                right: 0;
                top: 0;
                display: inline-block;
                margin-left: 15px;
                transition: all ease .4s;
                color: $titleColor;
            }
        }
    }
    &.codes-style{
        .codes-title{
            color:rgb(7, 52, 104);
            font-size: 1.4em;
            font-weight: 700;
            width:100%;
            display: flex;
            justify-content: center;
        }
        .codes-item{
            padding: 25px 25px 25px 25px;
            background-color: #F1F6FC;
            border-radius: 4px 4px 4px 4px;
            margin: 0 -12px 0 0;
            display: flex;

            .codes-icon{
                padding: 5px 5px 0px 0px;
                img{
                    height: 65px;
                    width: 65px;
                    max-width: unset;
                }
            }
            .codes-content{
                padding: 0px 0px 0px 15px;
                .codes-title{
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 30px;
                    margin-bottom: 10px;
                    color: white;
                    a{
                        color: white;
                        &:hover{
                            color: white;
                        }
                    } 
                }
                .codes-desc{
                    color: white;
                    margin-bottom: 0px; 
                    p {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    .shape-animation{
        position: relative;
        .shape-part{
            position: absolute;
            left: -50px;
            bottom: 150px;
        }
    }
}
@media #{$only_lg} {
    .rs-codes {
        &.main-home{
            &.style1 {
                .codes-item {
                    padding: 30px;
                    .codes-content{
                        .codes-text{
                            .codes-title {
                                font-size: 19px;
                            }
                        }
                    }
                }

            }
        }
    }
}

@media #{$lg} {
    .rs-codes {
        &.style2 {
            .flip-box-inner {
                .flip-box-wrap {
                    .front-part {
                        .front-content-part {
                            padding: 50px 30px;
                        }
                    }
                    .back-front {
                        height: 100%;
                    }
                }
            }
        }
        &.style6 {
            .codes-item {
                padding: 0px 15px 0px;
            }
        }
    }
    .rs-codes.style4 .codes-item,
    .rs-codes.style4.modify1 .codes-item {
        padding-left: 15px;
        padding-right: 15px;
    }
    
    .rs-codes.style8 .all-codes .codes-item .codes-wrap {
        padding: 30px 20px;
    }
    .rs-codes.style5 .codes-item {
        margin: 0;
        display: block;
    }
    .rs-codes.style5 .codes-item .codes-content {
        padding: 0;
        margin-top: 15px;
    }
    .rs-codes.style5 .codes-item .codes-content .codes-desc p {
        margin-bottom: 5px;
    }
}

@media #{$only_lg} {
    .rs-codes.style4 .codes-item .codes-content .codes-title {
        font-size: 18px;
        line-height: 28px;
    }
    .rs-codes.style3 .codes-item.codes-item {
        padding: 50px 30px;
    }
}


@media #{$md} {
    .rs-codes {
        &.main-home{
            &.style1{
                margin-top: 0;
            }
        }
    }
    .rs-codes.style4.modify1.codes3 .codes-item {
        margin: 0;
    }
    .rs-codes.style8 .all-codes .codes-item {
        width: 50%;
    }

    .rs-codes.style4 .codes-item .codes-content .codes-title {
        font-size: 20px;
        line-height: 30px;
    }

    .rs-codes.style3.modify2 .codes-item {
        display: block;
        padding: 35px 20px !important;
        .codes-content {
            padding: 0;            
        }
        &:hover{
            .codes-content  {
                padding: 0;
            }            
        }
    }
    .rs-codes.style3 .codes-item .codes-icon {
        margin-bottom: 20px;
    }
    
}

@media #{$sm} {
    .rs-codes {
        &.main-home{
            .codes-item {
                .codes-content{
                    .codes-text{
                        .codes-title {
                            font-size: 20px;
                            margin-bottom: 8px;
                        }
                    }
                }
            }
            &.style1{
                margin-top: 0;
            }
        }
        &.style3{
            &.modify2 {
                .codes-item {
                    margin: unset;
                }
            }
        }
    }

    .rs-codes .bg-section .shape-part .left-side img {
        height: 200px;
        width: 200px;
    }
}

@media #{$mobile} {
    .rs-codes.style4.modify1 .codes-item .codes-content .codes-title {
        font-size: 17px;
    }
    .rs-codes.style8 .all-codes .codes-item {
        width: 100%;
    }
    .rs-codes.style7 .codes-border img {
        width: 100%;
        height: 2px;
    }
    .rs-codes.style9 .codes-item {
        padding: 50px 30px 40px;
    }

}
