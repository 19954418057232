@import 'variables';
@import 'mixins';
@import 'common';
@import 'overlay';
@import 'header';
@import 'btn';
@import 'about';
@import 'slider';
@import 'banner';
@import 'footer';
@import 'services';
@import 'cta';
@import 'codes';
@import 'chooseus';
@import 'project';
@import 'testimonial';
@import 'counter';
@import 'video';
@import 'contact';
@import 'appointment_form';
@import 'blog';
@import 'brand';
@import 'newsletters';
@import 'faq';
@import 'process';
@import 'widget';
@import 'progress';
@import 'skills';
@import 'feature';
@import 'callus';
@import 'pricing';
@import 'breadcrumb';
@import 'pagination';
@import 'shop';
@import 'technology';
@import 'scrollup';
@import 'error';
@import 'responsive';
@import 'animations';
@import 'spacing';
@import 'mobile-menu';
@import 'canvas-menu';
@import 'search-modal';
@import '../../assets/fonts/flaticon';
@import '../../assets/fonts/fontAwesome';


